<template>
	<div class="container-fluid my-5">
		<!-- Content here -->
		<div class="card text-white gallery-text" :id="'gallery' + order">
			<div class="card-body">
				<div :id="'gallery' + mission + 'carousel'" class="carousel slide" data-bs-ride="carousel">
					<div class="carousel-inner">
						<div class="carousel-item active">
							<div class="container-fluid text-center">
								<br />
								<button class="btn btn-lg" style="text-decoration: none; color: white" v-if="route">
									<router-link :to="{ name: 'Mission', params: { mission: mission } }">
										<h3 class="card-title">
											{{ mission }}
											<svg style="width: 1em; height: 1em" viewBox="0 0 24 24">
												<path
													fill="white"
													d="M10,17V14H3V10H10V7L15,12L10,17M10,2H19A2,2 0 0,1 21,4V20A2,2 0 0,1 19,22H10A2,2 0 0,1 8,20V18H10V20H19V4H10V6H8V4A2,2 0 0,1 10,2Z"
												/>
											</svg>
										</h3>
									</router-link>
								</button>
								<h3 v-else class="card-title">
									{{ mission }}
								</h3>

								<br /><br />
								<div class="row justify-content-center">
									<div class="col" v-if="mission === 'Giveaways'">
										<div class="myspace">
											<div class="card-text">
												<div class="row">
													<div class="mt-3 intro-text">
														<span>
															Support us and win an Alien Landscape + a Pufu currency coin from the Trippy Gum Universe! <br />
															It's simple: <br />
														</span>
														<ol>
															<ul>
																<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
																	<path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
																</svg>
																Step 1: Follow Curiosoul on
																<a href="https://twitter.com/a_curiosoul" target="_blank">Twitter</a>
																and
																<a href="https://instagram.com/curiosoulartists" target="_blank">Instagram</a>
															</ul>
															<ul>
																<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
																	<path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
																</svg>
																Step 2: Follow Noob-chan's NFTs on
																<a href="https://twitter.com/noob_chan_nft" target="_blank">Twitter</a>
																and
																<a href="https://instagram.com/noob_chan_nft" target="_blank">Instagram</a>
															</ul>
															<ul>
																<svg style="width: 24px; height: 24px" viewBox="0 0 24 24">
																	<path fill="currentColor" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z" />
																</svg>
																Step 3: Drop your wallet address
																<a href="https://docs.google.com/forms/d/e/1FAIpQLSeKYuRI6yfmREjkneipJTB4BLNgkGtZmNwfy0Sd00pRRt1akA/viewform" target="_blank">
																	here
																</a>
															</ul>
														</ol>
													</div>
													<div class="row justify-content-sm-center justify-content-center">
														<div class="col-12 col-sm-12 col-md-12 col-lg-5">
															<video
																preload="metadata"
																controls
																src="https://d2jn1mvjd9iux5.cloudfront.net/collaborations/alien-landscapes/give+away.mp4#t=0.01"
																class="w-100"
															></video>
														</div>
														<div
															class="col-12 col-sm-12 col-md-12 col-lg-5 d-flex d-flex justify-content-center justify-content-sm-center justify-content-lg-end"
														>
															<img src="https://d2jn1mvjd9iux5.cloudfront.net/pufu+coin.png" class="rounded-circle img-fluid float-end w-50" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "MissionItem",
		components: {},
		props: {
			mission: {
				type: String,
				required: true
			},
			order: {
				type: String,
				required: true
			},
			route: {
				type: Boolean,
				default: true
			}
		},
		data() {
			return {};
		},
		mounted() {
			// let ids = getLevelIds(this.level);
			// let metadata = getMetadata(ids);
			// this.traits = generateFilters(metadata);
			// this.getMinimumRarity();
		},
		methods: {}
	};
</script>

<style scoped>
	.intro-text {
		letter-spacing: 1px;
		color: white;
		font-family: "Mukta Vaani", "cursive";
		font-size: 1.6em;
	}

	.card-title {
		word-spacing: 1vw;
		background: -webkit-linear-gradient(white, #38495a);
		background: linear-gradient(white, #38495a);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: "Monoton", "cursive";
		font-weight: 400;
		font-size: 3em;
	}

	.gallery-text {
		left: 0;
		right: 0;
		text-align: center;
		position: relative;
	}

	.myspace {
		background-color: rgba(0, 0, 0, 0.5);
		border-radius: 10px;
		margin: 0 10px 0 10px;
		font-family: "Mukta Vaani", "cursive";
	}

	.card {
		background-color: rgba(255, 255, 255, 0.1) !important;
	}

	h3 {
		background: -webkit-linear-gradient(white, #38495a);
		background: linear-gradient(white, #38495a);
		background-clip: text;
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
		font-family: "Monoton", "cursive";
		font-weight: 400;
		font-size: 3em;
	}

	.footer-list {
		text-align: left;
	}

	i,
	svg,
	use {
		width: 1em;
		height: 1em;
	}

	a {
		text-decoration: none;
	}

	ul.footer-list,
	ul.footer-list a {
		list-style-type: none;
		margin: 0;
		padding: 0;
		font-family: "Mukta Vaani", "cursive";
		font-weight: 400;
		font-size: 1em;
		text-decoration: none;
		color: white;
	}
</style>
