import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueLazyload from "vue-lazyload";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

//const loadImage = require("@/assets/loading.gif");
import loadImage from "@/assets/loading.gif";

Vue.use(VueLazyload, {
	attempt: 1,
	loading: loadImage
});

Vue.config.productionTip = false;

new Vue({
	router,
	render: (h) => h(App)
}).$mount("#app");
