import Vue from "vue";
import VueRouter from "vue-router";
import Container from "../views/Container.vue";
import Mission from "@/components/Mission";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Container
	},
	{
		path: "/mission/:mission",
		name: "Mission",
		component: Mission,
		props: true
	}
];

const router = new VueRouter({
	mode: "hash",
	routes,
	scrollBehavior() {
		return { x: 0, y: 0 };
	}
});

export default router;
