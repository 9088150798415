<template>
	<div>
		<Home />
	</div>
</template>

<script>
	// @ is an alias to /src
	import Home from "@/components/Home.vue";

	export default {
		name: "Container",
		components: {
			Home
		}
	};
</script>
