<template>
	<div class="myspace card card-block text-xs-center">
		<h4 class="card-title mb-1">
			<a :href="getBuyURL()" class="btn btn-warning btn-block btn-title w-100" target="_blank">
				<!-- <span v-if="isAvailable()">Buy</span>
				<span v-else>Sold</span>
				<br /> -->
				Alien Landscapes X Trippy Gum #{{ id }}
			</a>
		</h4>
		<video preload="metadata" controls class="img-fluid img-thumbnail" :src="getVideoURL(id)"></video>
	</div>
</template>

<script>
	export default {
		name: "VideoCard",
		props: {
			id: {
				type: String
			},
			mission: {
				type: String
			}
		},
		methods: {
			isAvailable() {
				let soldIds = [];
				return !soldIds.includes(this.id);
			},
			getVideoURL(id) {
				let src = "https://d2jn1mvjd9iux5.cloudfront.net/collaborations/alien-landscapes/";
				src += this.mission.replace(" ", "-").toLowerCase() + "/";
				src += id + ".mp4#t=0.01";
				return src;
			},
			getBuyURL() {
				//				return `https://opensea.io/assets/matic/0x6f544b9b643fa9fbef284dd4b09c5e010d4c2faf/${this.id}`;
			}
		}
	};
</script>

<style scoped>
	.btn-title {
		letter-spacing: 5px;
	}
</style>
